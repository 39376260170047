<template>
  <VueFinalModal
    :content-class="[
      'absolute bottom-0 !transform-none md:right-0 w-full md:h-full pt-6 md:pt-[34px]  ',
      (isChangeDir && appStore.isMobile) || defaultMobileView
        ? 'mobile-drawer'
        : 'md:w-[568px]',
      props.contentClass,
    ]"
    :swipe-to-close="modalSlideAttr.swipeToClose"
    :content-transition="modalSlideAttr.contentTransition"
    :overlay-transition="modalSlideAttr.overlayTransition"
  >
    <div
      :class="[
        'absolute z-50 block cursor-pointer  right-6 ',
        defaultMobileView
          ? '-top-11 md:-top-14 bg-white rounded-full p-2'
          : 'top-5 md:top-[34px]',
      ]"
    >
      <div @click="closeModal" class="flex item-center">
        <CloseIcon
          v-if="defaultMobileView"
          class="block w-5 h-5 text-black md:w-6 md:h-6"
        />
        <CloseCircleIcon
          v-else-if="isChangeDir && appStore.isMobile"
          class="block w-[30px] h-[30px] text-[#717477]"
        />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_870_38049)">
            <path
              d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_870_38049">
              <rect width="24" height="24" rx="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <span @click="closeModal" class="list-container-span"></span>
    <div class="relative z-auto h-full">
      <slot />
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal, useVfm } from "vue-final-modal";
import { useAppStore } from "~/store/app";
import CloseCircleIcon from "@/assets/svg/heroicons/x-circle.svg?component";
import CloseIcon from "@/assets/svg/closemobile.svg?component";

const props = defineProps({
  componentName: {
    type: String,
    default: "",
  },
  contentClass: {
    type: String,
    default: "bg-white",
  },
  isChangeDir: {
    type: Boolean,
    default: false,
  },
  defaultMobileView: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits();
const vfm = useVfm();
const appStore = useAppStore();

const isTransition = ref(true);

const modalSlideAttr = computed(() => {
  if ((props.isChangeDir && appStore.isMobile) || props.defaultMobileView) {
    return {
      swipeToClose: "down",
      contentTransition: "vfm-slide-down",
      overlayTransition: "vfm-fade",
    };
  } else {
    return {
      swipeToClose: "none",
      contentTransition: "vfm-slide-right",
      overlayTransition: "vfm-fade-right",
    };
  }
});

onMounted(() => {
  setTimeout(() => {
    isTransition.value = false;
  }, 200);
});

function closeModal() {
  vfm.closeAll();
}
</script>

<style lang="postcss">
.vfm {
  @apply !z-[100000000000];
}
.slide-enter-active,
.slide-leave-active {
  transition: 300ms;
}
.slide-enter-to {
  position: relative;
  left: 0;
}
.slide-leave {
  position: absolute;
}
.slide-enter {
  left: -100vw;
  position: absolute;
}
.slide-leave-to {
  right: -100vw;
}
.v--modal-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.list-container-span {
  @apply bg-[#C2C2C2] z-[10000] -top-3 w-12 h-1 left-[43%] md:hidden block absolute md:relative rounded-md;
}
.mobile-drawer {
  height: calc(100% - 200px);
}
@media (max-width: 767px) {
  .drawer-sidebar {
    height: calc(100vh - 110px);
    @apply left-0 rounded-t-2xl bottom-0;
  }
  .mobile-drawer {
    height: calc(100% - 95px);
    @apply rounded-t-2xl;
  }
}
</style>
